body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI',
        'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas,
        'Courier New', monospace;
}

.d-flex {
    display: flex;
}

.col {
    flex-direction: column;
}

.space-around {
    justify-content: space-around;
}

.full-width {
    width: 100%;
}

.text-center {
    text-align: center;
}

.superlight {
    font-weight: 100;
}

.light {
    font-weight: 300;
}

.bold {
    font-weight: 700;
}

.error {
    color: #b60000;
}

.success {
    color: #003a57;
}

.green {
    color: green;
}

.pink-bg {
    background-color: #ff8182;
}

.justify-center {
    justify-content: center;
}

.align-start {
    align-items: start;
}

.align-center {
    align-items: center;
}

.wrap {
    flex-wrap: wrap;
}

.no-wrap {
    text-wrap: nowrap;
}

.p-20 {
    padding: 20px;
}

.pt-16 {
    padding-top:16px;
}

.m-20 {
    margin:20px;
}

.m-0 {
    margin: 0;
}

.mb-4 {
    margin-bottom: 0.4em;
}

.mt-16 {
    margin-top: 16px;
}

.mt-8 {
    margin-top: 8px;
}

.m-auto {
    margin: auto;
}
