$cool-easing: cubic-bezier(0.25, 0.46, 0.45, 0.94);
$xs: 575px;
$sm: 690px;
$md: 894px;
$lg: 1184px;

.preload {
    background-color: #000000;
    height: 100vh;
    &-invisible {
        display: none;
    }
}

.temp-landing-page {
    min-height: 100vh;
    // height: 100vh;
    // width: 100vh;
    background: url('./../../images/large/landing-page-concept-mobile.png');
    background-size: cover;
    background-position: center;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease;

    @media (min-width: $xs) {
        background: url('./../../images/large/landing-page-concept.png');
    }
}

.white-text {
    color: aliceblue;
    text-shadow: 10px 12px 20px #000000;
}

.logo {
    height:30px;

    @media (min-width: $xs) {
        height:60px;
    }
}

.back-button {
    margin-top: -50px;
}

.header {
    margin-top: 396px;
    font-weight: 400;
    align-self: center;
    display: none;

    &-mobile {
        margin: -30px 0 8px 0;
        font-size: 1.4rem;
        font-weight: 400;
        color: rgb(44, 44, 44);
    }

    @media (min-width: $xs) {
        // margin-top: 390px;
        display: block;

        &-mobile {
            display: none;
        }
    }

    @media (min-width: $lg) {
        margin: 0;
        font-weight: 100;
        align-self: center;
    }
}

.grid {
    z-index: 10;
    position: relative;
    width:100%;
    min-height: 100vh;
    margin-top: auto;
    padding: 16px 16px 0;

    @media (min-width: $xs) {
        margin-top: 0;
        padding: 24px;
    }

    @media (min-width: $lg) {
        width: fit-content;
    }
}

.title {
    margin-top: 36vh;

    @media (min-width: $xs) {
        margin-top: 0;
    }
}

.form-box {
    // margin-top: auto;
    // border-radius: 4px;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 2px 1px -1px, rgba(0, 0, 0, 0.14) 0px 1px 1px 0px, rgba(0, 0, 0, 0.12) 0px 1px 3px 0px;
    background: radial-gradient(farthest-corner at 0% 0%, rgba(238, 210, 174, 0.73) 20%, rgba(148,187,233,1) 100%);
    // background: radial-gradient(farthest-corner at 0% 0%, rgba(255, 230, 197, 0.7) 20%, #001e4173 100%);
    backdrop-filter: blur(10px);
    padding: 24px 24px 24%;

    border-radius: 6px 6px 0 0;
    box-shadow: #37373798 7px 19px 17px 3px;
    position:fixed;
    bottom: -60px;

    @media (min-width: $xs) {
        position: unset;
        box-shadow: 12px 9px 17px 2px #1c1c1c4f;
    
        border-radius: 6px;
        width: auto;
        margin: 30px 0;
        padding: 34px;
    }
}



p {
    margin-top: 0;
    font-size: 14px;
    line-height: 1.2rem;
}

.text-shadow {
    text-shadow: 0px 0px 4px #fe8180;
    //font-weight: 200;
    // font-style: italic;
}



.bg {
    z-index: 0;
    position: absolute;
    // transition: transform 0.3s ease;
    transition: transform 0.3s $cool-easing;
    // img {
    //     width:100%;
    //     transition: transform 0.3s $cool-easing;
    // }
    top: 40%;
        left: 50%;
        transform: translate(-50%, -40%);
        width: 200%;
        display: none;

        @media (min-width: $md) {
            display:block;
        //     top: 40%;
        // left: 50%;
        // transform: translate(-50%, -50%);
            width: 120%;
        }

        @media (min-width: $lg) {
            top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        }
}

.daria {
    z-index: 2;
    margin-left: 5%;
    position: absolute;
    top: 5%;
    transition: transform 0.3s ease;
    display: block;
    
    
    img {
        // transition: perspective(400px) rotateX(0deg) rotateY(5deg)
        // scale3d(1, 1, 1);
        transition: transform 0.3s $cool-easing;
        
        width: 50%;
    }

    @media (min-width: $xs) {
        display: none;
    }

    @media (min-width: $sm) {
        display: block;
        top: 152px;
        margin-left: 60px;
        img {
            height:auto;
            width: calc(100vw - 474px);
        }
        
    }

    @media (min-width: $md) {

        img {
            height:auto;
            width:auto;
            }
    }

    @media (min-width: $lg) {
        top: 3%;
    }
}

.estrogen {
    z-index: 0;
    position: absolute;
    bottom: 61%;
    margin-left: 70%;
    img {
        transition: transform 0.3s $cool-easing;
        height: 63px;
    }

    @media (min-width: $xs) {
        margin-left: 30%;
        bottom: 22%;

        img {
            height: 122px;
        }
    }
}

.estrogen2 {
    z-index: 0;
    position: absolute;
    bottom: 45%;
    margin-left: 78%;
    img {
        transition: transform 0.3s $cool-easing;
        height: 23px;
    }

    @media (min-width: $xs) {
        margin-left: 20%;
        bottom: 36%;

        img {
            height: unset;
        }
    }
}
 
.darwin {
    z-index: 0;
    position: absolute;
    bottom: 32%;
    margin-left: 18%;
    img {
        transition: transform 0.3s $cool-easing;
        height: 124px;
    }

    @media (min-width: $xs) {
        margin-left: 24%;
        bottom: 36%;

        img {
            height: unset;
        }
    }
}

.cloud {
    z-index: 1;
    position: absolute;
    animation: 20s linear 1s infinite running chug;
    height: 54%;
        bottom: 10%;
        right: 0;
   
    img {
        transition: transform 0.3s $cool-easing;
    }
}

@keyframes chug {
    0% {
       transform: translateX(-150%);
       opacity: 0;
    }

    50% {
        opacity: 1;
    }

    100% {
       transform: translateX(100%);
    }
  }

.fg {
    z-index: 3;
    position: absolute;
    transition: transform 0.3s ease;
    display: none;
    right: -50px;
    img {
        transition: transform 0.3s $cool-easing;
    }

    @media (min-width: $xs) {
        display: block;
        bottom: -320px;
    }

    @media (min-height: 1081px) {
        bottom: -33%;
        right: -50px;
    }
}

.fg-2 {
    z-index: 2;
    position: absolute;
    transition: transform 0.3s ease;
    bottom: -340px;
    right: 378px;
    img {
        transition: transform 0.3s $cool-easing;
    }

    @media (min-height: 1081px) {
        bottom: -207px;
        right: 22px;
    }
}

/* button {
    background: #e85757;
    border: none;
    border-radius: 30px;
    cursor: pointer;
    display: block;
    font-size: 18px;
    font-weight: 700;
    padding: 16px;
    width: 120px;
    color: #fff;
  }
   */

   .thinking {
    z-index: 1;
    position: absolute;
    top: 90px;
    right: calc((100vw - 832px)/2);

    img {
        width:44%;
    }

    @media (min-width: $xs) {
        display: none;
    }
}

   .container {
    position: absolute;
    top: 154px;
    right: calc((100vw - 365px)/2);
    display:none;

    @media (min-width: $xs) {
        display: block;
    }

    @media (min-width: $sm) {
        right: 4%;
        
    }

    @media (min-width: $md) {

        top: 9%;
    }

    @media (min-width: $lg) {
        top: 4%;
    }

    .card {
        background: url('./../../images/art/small-frame.png') no-repeat;
        background-size: cover;
        width: 365px;
        margin: auto;
        height: 319px;
        padding: 80px;
        position: relative;
        color: #fff;
        transition: transform 0.3s $cool-easing;
        transform-style: preserve-3d;
        transform: perspective(400px) rotateX(0deg) rotateY(-10deg)
            scale3d(1, 1, 1);
        will-change: transform;
        
    
        @media (min-width: $xs) {
            
            height: 350px;
            padding: 101px;
            width: 400px;
        }
    }
}


// .card::before {
//   content: "";
//   background: rgba(0, 0, 0, 0.4);
//   position: absolute;
//   height: 100%;
//   width: 100%;
//   left: 0;
//   right: 0;
//   top: 0;
//   bottom: 0;
//   z-index: 0;
// }

.card:hover .content {
    transform: translateZ(22px);
}

.content {
    position: relative;
    // z-index: 1;
    transition: transform 0.3s ease;
}
